var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    [
      _c("c-table", {
        ref: "table",
        attrs: {
          landscapeMode: "",
          title: "점검 결과 별 개선사항 목록",
          columns: _vm.grid.columns,
          gridHeight: _vm.grid.height,
          data: _vm.grid.data,
          merge: _vm.grid.merge,
          filtering: false,
          columnSetting: false,
          usePaging: false,
          editable: _vm.editInfo && !_vm.disabled,
        },
        on: { linkClick: _vm.linkClick, innerBtnClicked: _vm.innerBtnClicked },
      }),
      _c("c-dialog", { attrs: { param: _vm.popupOptions } }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }