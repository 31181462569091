<template>
  <div>
    <c-table
      ref="table"
      landscapeMode
      title="점검 결과 별 개선사항 목록"
      :columns="grid.columns"
      :gridHeight="grid.height"
      :data="grid.data"
      :merge="grid.merge"
      :filtering="false"
      :columnSetting="false"
      :usePaging="false"
      :editable="editInfo&&!disabled"
      @linkClick="linkClick"
      @innerBtnClicked="innerBtnClicked"
    >
    </c-table>
    <c-dialog :param="popupOptions"></c-dialog>
  </div>
</template>

<script>
import selectConfig from '@/js/selectConfig';
export default {
  name: 'pre-startup-impr',
  props: {
    param: {
      type: Object,
      default: () => ({
        sopPrestartupCheckId: '',
        psrCheckStatusCd: '',
        isSearch: false,
      }),
    },
  },
  data() {
    return {
      grid: {
        merge: [
          { index: 0, colName: 'equipmentName' },
          { index: 1, colName: 'mergeCheck1' },
          { index: 2, colName: 'mergeCheck2' },
          { index: 3, colName: 'mergeCheck2' },
          { index: 4, colName: 'mergeCheck2' },
          { index: 5, colName: 'mergeCheck2' },
          { index: 11, colName: 'mergeCheck2' },
        ],
        columns: [],
        data: [],
      },
      editable: true,
      listUrl: '',
      popupOptions: {
        target: null,
        title: '',
        visible: false,
        width: '80%',
        param: {},
        closeCallback: null,
      },
    };
  },
  beforeCreate() {},
  created() {},
  beforeMount() {
    Object.assign(this.$data, this.$options.data());
  },
  mounted() {
    this.init();
  },
  beforeDestroy() {
  },
  computed: {
    editInfo() {
      return this.editable && !this.param.isSearch
    },
    disabled() {
      // 점검완료인 경우 비활성화
      return this.param.psrCheckStatusCd === 'PCSC000005';
    }
  },
  methods: {
    init() {
      // role setting
      this.editable = this.$route.meta.editable;
      // url setting
      this.listUrl = selectConfig.sop.psr.checkItemImprove.list.url
      // code setting
      this.$comm.getComboItems('IBM_CLASS_CD').then(_result1 => {
        this.$comm.getStepItems('IBM_STEP_CD').then(_result2 => {
          this.grid.columns = [
            {
              name: 'equipmentName', 
              field: 'equipmentName',
              label: '설비',
              align: 'center',
              style: 'width:50px',
              sortable: false,
              type: 'link'
            },
            {
              name: 'psrCheckTypeName', 
              field: 'psrCheckTypeName',
              label: '점검유형',
              align: 'center',
              style: 'width:50px',
              sortable: false,
            },
            {
              name: 'checkItemName',
              field: 'checkItemName',
              label: '점검항목',
              sortable: false,
              child: [
                {
                  name: 'level',
                  field: 'level',
                  label: '대분류/중분류/소분류',
                  align: 'left',
                  style: 'width:300px',
                  sortable: false,
                },
              ]
            },
            {
              name: 'psrCheckPeriodName',
              field: 'psrCheckPeriodName',
              label: '점검시기',
              align: 'center',
              style: 'width:45px',
              sortable: false,
            },
            {
              name: 'judgmentResultFlagName',
              field: 'judgmentResultFlagName',
              label: '적합여부',
              align: 'center',
              style: 'width:45px',
              sortable: false,
            },
            {
              name: 'checkResult',
              field: 'checkResult',
              label: '점검결과',
              align: 'left',
              style: 'width:200px',
              sortable: false,
              innerBtn: true,
              btns: [
                { label: '', icon: 'add', color: 'light-blue', value: '1', tooltip: '개선사항추가' },
                { label: '', icon: 'add', color: 'orange', value: '2', tooltip: '즉시조치사항추가' },
              ],
            },
            {
              name: 'ibmTitle',
              field: 'ibmTitle',
              label: '개선요청 제목',
              align: 'left',
              type: 'link',
              style: 'width:300px',
              sortable: true,
            },
            {
              name: 'ibmClassCd',
              field: 'ibmClassCd',
              label: '개선구분',
              align: 'center',
              style: 'width:100px',
              sortable: true,
              type: 'tag',
              colorItems: _result1
            },
            {
              name: 'ibmStepCd',
              field: 'ibmStepCd',
              label: '진행상태',
              align: 'center',
              style: 'width:100px',
              type: 'tag',
              colorItems: _result2,
              sortable: false
            },
            {
              name: 'improveRequest',
              field: 'improveRequest',
              label: '요청부서정보',
              align: 'center',
              style: 'width:200px',
              sortable: true,
            },
            {
              name: 'actionDeptName',
              field: 'actionDeptName',
              label: '조치부서',
              align: 'center',
              style: 'width:100px',
              sortable: true,
            },
            {
              name: 'remark',
              field: 'remark',
              label: '비고',
              align: 'left',
              style: 'width:150px',
              sortable: false,
            },
          ];
        });
      });
      // list setting
      this.getList();
    },
    getList() {
      if (this.param.sopPrestartupCheckId) {
        this.$http.url = this.listUrl;
        this.$http.type = 'GET';
        this.$http.param = {
          sopPrestartupCheckId: this.param.sopPrestartupCheckId
        }
        this.$http.request((_result) => {
          this.grid.data = _result.data;
        },);
      }
    },
    /* eslint-disable no-unused-vars */
    linkClick(row, col, index) {
      if (col.name === 'ibmTitle') {
        if (row.ibmClassCd === 'IC00000001') {
          this.popupOptions.title = '개선';
          this.popupOptions.target = () => import(`${'@/pages/sop/ibm/imprDetail.vue'}`);
        } else {
          this.popupOptions.title = '즉시조치';
          this.popupOptions.target = () => import(`${'@/pages/sop/ibm/imprImmDetail.vue'}`);
        }
        this.popupOptions.param = {
          isSearch: this.param.isSearch,
          sopImprovementId: row.sopImprovementId,
          ibmTaskTypeCd: row.ibmTaskTypeCd,
          ibmTaskUnderTypeCd: row.ibmTaskUnderTypeCd,
        };
        this.popupOptions.width = '80%';
        this.popupOptions.visible = true;
        this.popupOptions.closeCallback = this.closeImprPopup;
      } else {
        this.popupOptions.title = '설비 상세';
        this.popupOptions.param = row;
        this.popupOptions.target = () => import(`${'@/pages/mdm/fim/equipmentDetail.vue'}`);
        this.popupOptions.width = '90%';
        this.popupOptions.visible = true;
        this.popupOptions.closeCallback = this.closeFacilityPopup;
      }
    },
    closeFacilityPopup() {
      this.popupOptions.target = null;
      this.popupOptions.visible = false;
    },
    closeImprPopup(result) {
      this.popupOptions.target = null;
      this.popupOptions.visible = false;

      this.getList();
    },
    /* eslint-disable no-unused-vars */
    innerBtnClicked(col, props, btn) {
      if (btn.value === '1') {
        this.popupOptions.title = '개선 요청';
        this.popupOptions.param = {
          requestContents: props.row.level,
          relationTableKey: props.row.sopPrestartupEquipmentHasCheckItemId,
          ibmTaskTypeCd: 'ITT0000030',
          ibmTaskUnderTypeCd: 'ITTU000050',
        };
        this.popupOptions.target = () => import(`${'@/pages/sop/ibm/imprDetail.vue'}`);
        this.popupOptions.width = '100%';
        this.popupOptions.visible = true;
        this.popupOptions.closeCallback = this.closeAddImprPopup;
      } else {
        this.popupOptions.title = '즉시개선'; // 즉시개선 상세
        this.popupOptions.param = {
          requestContents: props.row.level,
          relationTableKey: props.row.sopPrestartupEquipmentHasCheckItemId,
          ibmTaskTypeCd: 'ITT0000030',
          ibmTaskUnderTypeCd: 'ITTU000050',
        };
        this.popupOptions.target = () => import(`${'@/pages/sop/ibm/imprImmDetail.vue'}`);
        this.popupOptions.width = '100%';
        this.popupOptions.visible = true;
        this.popupOptions.closeCallback = this.closeAddImprPopup;
      }
    },
    closeAddImprPopup(result) {
      this.popupOptions.target = null;
      this.popupOptions.visible = false;

      this.getList();
    },
  }
};
</script>
